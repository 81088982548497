import { UseSeoMetaInput } from 'unhead';
import { StoryBlokSeoPlugin } from '~/constants/types/storyblok';

export default function useSeoCreator() {
  /**
   * There is probably a better way to do this but my brain is fried today
   */
  const createMeta = (input: StoryBlokSeoPlugin) => {
    const seoMeta = {} as UseSeoMetaInput;

    // Title
    let title = input.title || '';
    let ogTitle = input.og_title || '';
    let twitterTitle = input.twitter_title || '';
    if (title === '' && (ogTitle !== '' || twitterTitle !== '')) {
      title  = ogTitle !== '' ? ogTitle : twitterTitle;
    }
    if (ogTitle === '' && (title !== '' || twitterTitle !== '')) {
      ogTitle  = title !== '' ? title : twitterTitle;
    }
    if (twitterTitle === '' && (title !== '' || ogTitle !== '')) {
      twitterTitle  = title !== '' ? title : ogTitle;
    }
    if (title !== '') {
      seoMeta.title = title;
    }
    if (ogTitle !== '') {
      seoMeta.ogTitle = ogTitle;
    }
    if (twitterTitle !== '') {
      seoMeta.twitterTitle = twitterTitle;
    }

    // Image
    let ogImage = input.og_image || '';
    let twitterImage = input.twitter_image || '';
    if (ogImage === '' && twitterImage !== '') {
      ogImage = twitterImage;
    }
    if (twitterImage === '' && ogImage !== '') {
      twitterImage = ogImage;
    }
    if (ogImage !== '') {
      seoMeta.ogImage = ogImage;
    }
    if (twitterImage !== '') {
      seoMeta.twitterImage = twitterImage;
    }

    // Description
    let description = input.description || '';
    let ogDescription = input.og_description || '';
    let twitterDescription = input.twitter_description || '';
    if (description === '' && (ogDescription !== '' || twitterDescription !== '')) {
      description = ogDescription !== '' ? ogDescription : twitterDescription;
    }
    if (ogDescription === '' && (description !== '' || twitterDescription !== '')) {
      ogDescription = description !== '' ? description : twitterDescription;
    }
    if (twitterDescription === '' && (ogDescription !== '' || description !== '')) {
      twitterDescription = description !== '' ? description : ogDescription;
    }
    if (description !== '') {
      seoMeta.description = description;
    }
    if (ogDescription !== '') {
      seoMeta.ogDescription = ogDescription;
    }
    if (twitterDescription !== '') {
      seoMeta.twitterDescription = twitterDescription;
    }
    useServerSeoMeta(seoMeta);
  };

  return {
    createMeta,
  };
}
